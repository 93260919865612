import React from "react"
import { shape } from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SearchWrapper from "../components/molecules/search-wrapper"
import PageTitle from "../components/atoms/page-title"

const propTypes = {
  intl: shape().isRequired,
  location: shape().isRequired,
}

const Search = ({ intl, location }) => {
  const { locale } = intl
  const slugs = {
    fi: "/search",
    en: "/search",
  }
  return (
    <Layout
      slugs={slugs}
      currentPath={`/${locale}${slugs[locale]}`}
      colourTheme="mercury"
    >
      <SEO title="Thanks for your interest" location={location.pathname} />
      <PageTitle>{intl.formatMessage({ id: "search.search" })}</PageTitle>
      <SearchWrapper location={location} />
    </Layout>
  )
}

Search.propTypes = propTypes

export default injectIntl(Search)
